// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery
//= require turbolinks
//= require_tree.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("packs/custom");

require("./nested-forms/addFields");
require("./nested-forms/removeFields");
require("bootstrap-icons/font/bootstrap-icons.css");

import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all";
