$( document ).on('turbolinks:load', function() {
  // $('#meeting').on('click', function(){
  //   if ($(this).is(":checked") == true) {
  //     $('.default_meetings').hide()
  //     $('.earlier_meetings').show()
  //   }else{
  //     $('.default_meetings').show()
  //     $('.earlier_meetings').hide()
  //   } 
  // });
})